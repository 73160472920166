.tabela-aulas {
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 30px;
    position: relative;
    flex-direction: column;
    padding-bottom: 30px;
    justify-content: flex-start;
    background-color: #212121;
  }
  .container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .btn-add-aula {
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    height: 40px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon {
    fill: white;
    width: auto;
    height: 20px;
  }
  .text01 {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .container01 {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .container02 {
    gap: 10px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text02 {
    color: rgb(177, 177, 177);
    font-size:14px;
  }
  .container03 {
    gap: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text03 {
    color: rgb(177, 177, 177);
    font-size:14px;
  }
  .container04 {
    gap: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text04 {
    color: rgb(177, 177, 177);
    font-size:14px;
  }
  .tabela-usuario {
    display: flex;
    position: relative;
    border-color: #3c3c3c;
    border-width: 1px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #3c3c3c;
  }
  .not-empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container05 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container06 {
    gap: 28px;
    display: flex;
    padding-top: 20px;
    border-color: #3c3c3c;
    border-width: 2px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 20px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .container07 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: flex-start;
  }
  .text05 {
    color: white;
    width: 20%;
    font-style: normal;
    font-weight: 500;
  }
  .text06 {
    color: white;
    width: 40%;
    font-style: normal;
    font-weight: 500;
  }
  .text07 {
    color: white;
    width: 10%;
    font-style: normal;
    font-weight: 500;
  }
  .text08 {
    color: white;
    width: 10%;
    font-style: normal;
    font-weight: 500;
  }
  .container08 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: flex-start;
  }
  .infos {
    gap: 20px;
    display: flex;
    align-items: stretch;
    padding-top: 20px;
    border-color: #717171;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    padding-bottom: 15px;
    justify-content: center;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .container09 {
    gap: 28px;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
  }
  .icon02 {
    fill: #ffffff;
    width: 16px;
    cursor: pointer;
    height: 16px;
    align-self: center;
    transition: 0.3s;
  }
  .icon02:hover {
    fill: #e4e4e4;
  }
  .icon04 {
    top: 0px;
    fill: white;
    right: 0px;
    width: auto;
    bottom: 0px;
    cursor: pointer;
    height: 18px;
    margin: auto;
    position: absolute;
    align-self: center;
    transition: 0.3s;
  }
  .icon04.ic {
    right:33px;
  }
  .icon04:hover {
    fill: #e40000;
  }
  .text09 {
    color: white;
    width: 20%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text10 {
    color: white;
    width: 40%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text11 {
    color: white;
    width: 10%;
    overflow: hidden;
    font-size: 14px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight:500;
    padding:10px;
    border-radius:10px;
    display:flex;
    align-items: center;
    justify-content:center;
  }
  .text12 {
    color: white;
    width: 10%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .paginao {
    gap: 20px;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: center;
  }
  .icon06 {
    fill: #c2c2c2;
    width: auto;
    height: 20px;
  }
  .text13 {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
  .icon08 {
    fill: #c2c2c2;
    width: auto;
    height: 20px;
  }
  .empty {
    gap: 24px;
    display: none;
    padding: 60px;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    background-color: #3c3c3c;
  }
  .icon10 {
    fill: rgba(255, 255, 255, 0.68);
    width: 50px;
    height: 50px;
  }
  .text14 {
    color: rgba(255, 255, 255, 0.68);
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  @media(max-width: 767px) {
    .text {
      width: 40%;
      font-size: 15px;
    }
    .text01 {
      width: 40%;
      font-size: 15px;
    }
    .text05 {
      width: 40%;
      font-size: 15px;
    }
    .text06 {
      width: 40%;
      font-size: 15px;
    }
    .text07 {
      width: 40%;
      font-size: 15px;
    }
    .text08 {
      width: 40%;
      font-size: 15px;
    }
    .text09 {
      width: 40%;
      font-size: 15px;
    }
    .text10 {
      width: 40%;
      font-size: 15px;
    }
    .text11 {
      width: 40%;
      font-size: 15px;
    }
    .text12 {
      width: 40%;
      font-size: 15px;
    }
    .text14 {
      width: 60%;
      font-size: 15px;
    }
  }
  @media(max-width: 479px) {
    .infos {
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 12px;
    }
    .text09 {
      word-break: break-all;
    }
    .text10 {
      word-break: break-all;
    }
    .text11 {
      word-break: break-all;
    }
    .text12 {
      word-break: break-all;
    }
  }

  .input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }
  