.react-calendar {
    width: 90%;
    margin: 0 auto;
    max-width: 100%;
    background: #171717;
    /* neutro escuro */
    /* border: 1px solid #404040; */
    /* neutro claro */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    color: #737373;
}

.react-calendar__month-view__days__day.day-with-availability {
    background: #22c55e;
    color: white;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #404040;
    /* neutro claro */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #262626;
    /* neutro médio */
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
    color: #737373;
    /* neutro claro */
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #262626;
    /* laranja */
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #404040;
    /* neutro claro */
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
}

.react-calendar__tile:disabled {
    background-color: #404040;
    /* neutro claro */
    color: #9ca3af;
    /* neutro médio */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #262626;
    /* neutro médio */
}

.react-calendar__tile--now {
    background: #9a3412;
    /* laranja */
    color: #fff;
    /* branco */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #fb923c;
    /* laranja mais claro */
}

.react-calendar__tile--hasActive {
    background: #fb923c;
    /* laranja mais claro */
    color: #fff;
    /* branco */
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #fdba74;
    /* laranja ainda mais claro */
}

.react-calendar__tile--active {
    background: #f97316;
    /* laranja */
    color: #fff;
    /* branco */
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #ea580c;
    /* laranja mais escuro */
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #262626;
    /* neutro médio */
}