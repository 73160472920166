footer {
    background-color: black;
    width: 100%;
    color: white;
    padding: 15px 0px 15px 0px;
}

.footer-content {
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: 12px;
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-link {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footer-link:hover {
    color: #E86811;
    text-decoration: none;
    cursor: pointer;
}


.separator {
    color: white;
    margin: 0 10px;
}

.aside-text7 {
    text-align: center;
    margin-top: 10px;
}