.menu-consultor-container {
    gap: 30px;
    height: 100%;
    display: flex;
    position: fixed;
    top:70px;
    min-width: 320px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    background-color: #212121;
  }
  .menu-consultor-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .menu-consultor-menu svg {
    min-width:18px;
  }
  .menu-consultor-container1 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon {
    fill: #ffffff;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container2 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon02 {
    fill: white;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text1 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container3 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon04 {
    fill: white;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text2 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container4 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon06 {
    fill: #ffffff;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text3 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container5 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    padding-left: 15px 20px;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon08 {
    fill: white;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text4 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container6 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon10 {
    fill: white;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text5 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container7 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 2px solid #3c3c3c;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon12 {
    fill: var(--dl-color-gray-white);
    width: auto;
    height: 18px;
  }
  .menu-consultor-text6 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-container8 {
    gap: 15px;
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-left:4px solid transparent;
    flex-direction: row;
  }
  .menu-consultor-icon14 {
    fill: #ffffff;
    width: auto;
    height: 18px;
  }
  .menu-consultor-text7 {
    color: white;
    font-size: 14px;
    line-height: 1;
  }
  .menu-consultor-footer {
    gap: 15px;
    display: flex;
    padding: 0 20px;
    flex-direction: column;
  }
  .menu-consultor-navlink {
    color: #6d6d6d;
    font-size: 13px;
    text-decoration: none;
  }
  .menu-consultor-navlink1 {
    color: #6d6d6d;
    font-size: 13px;
    text-decoration: none;
  }
  .menu-consultor-text8 {
    color: #6d6d6d;
    font-size: 13px;
  }
  .menu-consultor-root-class-name {
    align-self: flex-start;
  }
  .activeMenu span {
    color:#E86811;
  }
  
  .activeMenu {
    border-left:4px solid #E86811;
  }
  
  .activeMenu svg {
    fill:#E86811;
  }