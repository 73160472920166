.container {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.89);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index:9999999999;
  }
  .container01 {
    width: 90%;
    display: flex;
    max-width: 600px;
    background: #3b3b3b;
    border-radius: 10px;
    flex-direction: column;
  }
  .head {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: rgb(243, 243, 243);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .fechar-popup {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
  }
  .icon {
    fill: #3b3b3b;
    width: 18px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;
  }
  .icon:hover {
    filter: brightness(1.3);
  }
  .form {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    overflow: auto;
    border-top: 1px solid #575757;
    max-height: 50vh;
    align-items: flex-start;
    padding-left: 20px 30px;
    border-bottom: 1px solid #575757;
    flex-direction: column;
  }
  .container02 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .select {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    font-size: 14px;
    background: #6a6a6a;
    padding-left: 20px;
    border-radius: 10px;
    padding-right: 20px;
  }
  .container03 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label1 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .select1 {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    font-size: 14px;
    background: #6a6a6a;
    padding-left: 20px;
    border-radius: 10px;
    padding-right: 20px;
  }
  .container04 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label2 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .textinput {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    background: #6a6a6a;
    border-radius: 10px;
  }
  .container05 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label3 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .container06 {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .image {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .fechar-popup1 {
    top: 40%;
    left: 40px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    position: absolute;
    transform: translateY(-40%);
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon2 {
    fill: #fff;
    width: auto;
    height: 12px;
  }
  .container07 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label4 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .textinput1 {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    background: #6a6a6a;
    border-radius: 10px;
  }
  .container08 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label5 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .select2 {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    font-size: 14px;
    background: #6a6a6a;
    padding-left: 20px;
    border-radius: 10px;
    padding-right: 20px;
  }
  .tags {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
  }
  .tag {
    gap: 5px;
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    background-color: #212121;
  }
  .label6 {
    color: rgb(243, 243, 243);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .icon4 {
    fill: white;
    width: auto;
    cursor: pointer;
    height: 14px;
    transition: 0.3s;
  }
  .icon4:hover {
    filter: brightness(1.3);
  }
  .container09 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label7 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .textinput2 {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    background: #6a6a6a;
    border-radius: 10px;
  }
  .tags1 {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
  }
  .tag1 {
    gap: 5px;
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    background-color: #212121;
  }
  .label8 {
    color: rgb(243, 243, 243);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .icon6 {
    fill: white;
    width: auto;
    cursor: pointer;
    height: 14px;
    transition: 0.3s;
  }
  .icon6:hover {
    filter: brightness(1.3);
  }
  .container10 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .button {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    background: rgb(100 100 100);
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: rgb(71 71 71);
  }
  .button:hover {
    filter: brightness(1.3);
  }
  .button1 {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .button1:hover {
    filter: brightness(1.3);
  }
  
  .resultados {
    width:100%;
    display:flex;
    gap:15px;
  }

  .resultados .resultado {
    width:24%;
    display:flex;
    flex-direction:column;
    gap:4px;
    cursor:pointer;
  }

  .resultados .resultado img {
    width:100%;
    height:70px;
    object-fit:contain;
    object-position:center;
    border-radius:5px;
    background:black;
  }

  .resultados .resultado p {
    color:#9e9e9e;
    font-size:12px;
  }