@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-default-white: rgba(245, 246, 248, 1);
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-color-default-gradiente: rgba(255, 91, 28, 1);
  --dl-color-default-laranjaapp: rgba(255, 91, 28, 1);
  --dl-space-space-oneandhalfunits: 24px;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #0f0f0f;
  overflow-x: hidden;
}

.pagina-group {
    background-image: var(--background-image, none);
}

.pagina-group:hover {
    background-image: attr(data-banner);
}



.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.teleport-show {
  display: flex !important;
  transform: none !important;
}

.Heading {
  font-size: 32px;
  font-family: Fira Sans;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.Content {
  font-size: 16px;
  font-family: Fira Sans;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.Toastify__toast-theme--dark {
  background: #2e2e2e !important;
  top: 50px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


/*.l-cont {
  position: relative;
  left: 170px;
  padding-right: 20px;
  max-width: calc(100% - 340px);
  margin: 0 auto;
}*/


@media screen and (max-width:991px) {
  /*.l-cont {
    min-width: 100% !important;
    position: initial;

  }*/
}

@media screen and (min-width:1650px) {
  /*.l-cont {
    max-width: 1350px;
  }*/
}


.input-dark {
  color: #c6c6c6;
  width: 100%;
  height: 40px;
  outline: none;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 10px;
  background: #6a6a6a;
}

.dropdown {
  color: #c6c6c6;
  width: 100%;
  height: 40px;
  outline: none;
  font-size: 14px;
  background: #6a6a6a;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.loading-container {
  position: relative;
  width: 90%;
  height: 10px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto;
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--highlight-color);
  border-radius: 10px;
  animation: loadingAnimation 1.5s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/* Estilos da barra de rolagem */
::-webkit-scrollbar {
  width: 7px;
  /* Largura da barra de rolagem */
}

/* Estilos do trilho da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #0c0c0c;
  /* Cor de fundo do trilho */
  border-radius: 3px;
  /* Bordas arredondadas do trilho */
}

/* Estilos do polegar (alça) da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #212121;
  /* Cor de fundo do polegar */
  border-radius: 3px;
  /* Bordas arredondadas do polegar */
}

/* Estilos da área de destaque (quando o mouse passa sobre a barra de rolagem) */
::-webkit-scrollbar-thumb:hover {
  background-color: #e86812ce;
  ;
  /* Cor de destaque do polegar */
}

/* Estilos do canto inferior direito (quando há uma área de rolagem) */
::-webkit-scrollbar-corner {
  background-color: #3c3c3c;
  /* Cor de fundo do canto inferior direito */
}