.container {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.89);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index:99999;
  }
  .container1 {
    width: 90%;
    display: flex;
    max-width: 600px;
    background: #3b3b3b;
    border-radius: 10px;
    flex-direction: column;
  }
  .head {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: rgb(243, 243, 243);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .fechar-popup {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
  }
  .icon {
    fill: #3b3b3b;
    width: 18px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;
  }
  .icon:hover {
    filter: brightness(1.3);
  }
  .opcoes {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    overflow: auto;
    flex-wrap: wrap;
    border-top: 1px solid #575757;
    max-height: 50vh;
    align-items: flex-start;
    padding-left: 20px 30px;
    border-bottom: 1px solid #575757;
    flex-direction: row;
    justify-content: flex-start;
  }
  .form-input-grp {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text01 {
    color: white;
  }
  .form-input-grp1 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text02 {
    color: white;
  }
  .form-input-grp2 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text03 {
    color: white;
  }
  .form-input-grp3 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text04 {
    color: white;
  }
  .form-input-grp4 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text05 {
    color: white;
  }
  .form-input-grp5 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text06 {
    color: white;
  }
  .form-input-grp6 {
    gap: 10px;
    width: 48%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text07 {
    color: white;
  }
  .form-input-grp7 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
  .text08 {
    margin:15px 0 10px 0;
    color: white;
  }
  .head1 {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .b-salvar {
    gap: 8px;
    display: flex;
    align-self: center;
    transition: 0.3s;
    padding-top: 12px;
    padding-left: 14px;
    border-radius: 10px;
    padding-right: 14px;
    flex-direction: row;
    padding-bottom: 12px;
    justify-content: center;
    background-color: rgb(100 100 100);
    cursor:pointer;
  }
  .b-salvar:hover {
    filter: brightness(1.2);
  }
  .text09 {
    color: white;
    font-size: 16px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  .b-salvar1 {
    gap: 8px;
    display: flex;
    cursor:pointer;
    align-self: center;
    transition: 0.3s;
    padding-top: 12px;
    padding-left: 14px;
    border-radius: 10px;
    padding-right: 14px;
    flex-direction: row;
    padding-bottom: 12px;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .b-salvar1:hover {
    background-color: #b7520e;
  }
  .text10 {
    color: white;
    font-size: 16px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  @media(max-width: 767px) {
    .b-salvar {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .text09 {
      font-size: 16px;
    }
    .b-salvar1 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .text10 {
      font-size: 16px;
    }
  }
  @media(max-width: 479px) {
    .opcoes {
      flex-direction: column;
    }
  }
  .input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }

  .input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }
  

  .form-input-grp7 > div {
    display:grid;
    grid-template-columns: repeat(2,1fr);
    gap:15px;
  }

  .form-input-grp7 > div > div {
    display:flex;
    flex-direction:column;
    gap:10px;
  }
  .form-input-grp7 > div > div > div {
    display:flex;
    gap:5px;
  }

  .form-input-grp7 > div > div h3 {
    font-weight: 400;
    font-size:16px;
    color:#979797
  }

  .form-input-grp7 > div > div > button {
    background: #67ff00;
    padding:10px;
    font-size: 12px;
    border-radius:5px;
    font-weight:500;
  }

  .form-input-grp7 > div > div svg {
    fill:red;
  }

  .container3 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label1 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .container4 {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .image {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .fechar-popup1 {
    top: 50%;
    right: -12px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon2 {
    fill: #fff;
    width: auto;
    height: 12px;
  }
