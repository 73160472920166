.admin-container {
  gap: 5px;
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
}
.admin-container001 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  padding-top:70px;
}
.admin-conteudo {
  width:100%;
}