.popup-curso-container {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.89);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index:99999999;
  }
  .popup-curso-container1 {
    width: 90%;
    display: flex;
    max-width: 600px;
    background: #3b3b3b;
    border-radius: 10px;
    flex-direction: column;
  }
  .popup-curso-head {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .popup-curso-text {
    color: rgb(243, 243, 243);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .popup-curso-fechar-popup {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
  }
  .popup-curso-icon {
    fill: #3b3b3b;
    width: 18px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;
  }
  .popup-curso-icon:hover {
    filter: brightness(1.3);
  }
  .popup-curso-form {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    overflow: auto;
    border-top: 1px solid #575757;
    max-height: 60vh;
    align-items: flex-start;
    border-bottom: 1px solid #575757;
    flex-direction: column;
  }
  .popup-curso-container2 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .popup-curso-label {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .popup-curso-textinput {
    color: #c6c6c6;
    width: 100%;
    height: 45px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    background: #6a6a6a;
    border-radius: 10px;
  }
  button[disabled] {
    opacity: .6;
    cursor:initial
  }

  button[disabled]:hover {
    filter:brightness(1)!important;
  }
  .popup-curso-container3 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .popup-curso-label1 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .popup-curso-container4 {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .popup-curso-image {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .popup-curso-fechar-popup1 {
    top: 50%;
    right: -12px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .popup-curso-icon2 {
    fill: #fff;
    width: auto;
    height: 12px;
  }
  .popup-curso-container5 {
    gap: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .popup-curso-container6 {
    gap: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .popup-curso-label2 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .popup-curso-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 5px;
  }
  .popup-curso-container7 {
    gap: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .popup-curso-label3 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .popup-curso-checkbox1 {
    width: 16px;
    height: 16px;
    border-radius: 5px;
  }
  .popup-curso-container8 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .popup-curso-button {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    background: rgb(100 100 100);
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: rgb(71 71 71);
  }
  .popup-curso-button:hover {
    filter: brightness(1.3);
  }
  .popup-curso-button1 {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .popup-curso-button1:hover {
    filter: brightness(1.3);
  }
  