.termos-de-uso-container {
  gap: 30px;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0f0f0f;
}

.termos-de-uso-container1 {
  gap: 30px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1600px;
  margin: 0 auto;
}

.termos-de-uso-capa {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.termos-de-uso-image {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.termos-de-uso-container2 {
  gap: 10px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: 30px;
  position: absolute;
  align-self: center;
  background: rgb(0, 0, 0, .5);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.termos-de-uso-text {
  color: white;
  font-size: 36px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}

.termos-de-uso-text001 {
  color: white;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 400;
}

.termos-de-uso-conteudo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
}

.termos-de-uso-descricao {
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-self: center;
  background: #212121;
  margin-left: 20px;
  padding-top: 50px;
  margin-right: 20px;
  padding-left: 30px;
  border-radius: 10px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 50px;
}

.termos-de-uso-text002 {
  color: white;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.termos-de-uso-text003 {
  color: #e0e0e0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

.termos-de-uso-text004 {
  font-weight: 300;
}

.termos-de-uso-text005 {
  font-weight: 300;
}

.termos-de-uso-text006 {
  font-weight: 300;
}

.termos-de-uso-text007 {
  font-weight: 300;
}

.termos-de-uso-text008 {
  font-weight: 300;
}

.termos-de-uso-text009 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text010 {
  font-style: normal;
}

.termos-de-uso-text011 {
  font-weight: 500;
}

.termos-de-uso-text012 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text013 {
  font-style: normal;
}

.termos-de-uso-text014 {
  font-weight: 300;
}

.termos-de-uso-text015 {
  font-weight: 300;
}

.termos-de-uso-text016 {
  font-weight: 300;
}

.termos-de-uso-text017 {
  font-weight: 300;
}

.termos-de-uso-text018 {
  font-weight: 300;
}

.termos-de-uso-text019 {
  font-weight: 300;
}

.termos-de-uso-text020 {
  font-style: normal;
}

.termos-de-uso-text021 {
  font-weight: 500;
}

.termos-de-uso-text022 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text023 {
  font-style: normal;
}

.termos-de-uso-text024 {
  font-weight: 300;
}

.termos-de-uso-text025 {
  font-weight: 300;
}

.termos-de-uso-text026 {
  font-weight: 300;
}

.termos-de-uso-text027 {
  font-weight: 300;
}

.termos-de-uso-text028 {
  font-weight: 300;
}

.termos-de-uso-text029 {
  font-weight: 300;
}

.termos-de-uso-text030 {
  font-weight: 300;
}

.termos-de-uso-text031 {
  font-weight: 300;
}

.termos-de-uso-text032 {
  font-weight: 300;
}

.termos-de-uso-text033 {
  font-weight: 300;
}

.termos-de-uso-text034 {
  font-weight: 300;
}

.termos-de-uso-text035 {
  font-weight: 300;
}

.termos-de-uso-text036 {
  font-weight: 300;
}

.termos-de-uso-text037 {
  font-weight: 300;
}

.termos-de-uso-text038 {
  font-weight: 300;
}

.termos-de-uso-text039 {
  font-weight: 300;
}

.termos-de-uso-text040 {
  font-weight: 300;
}

.termos-de-uso-text041 {
  font-weight: 300;
}

.termos-de-uso-text042 {
  font-weight: 300;
}

.termos-de-uso-text043 {
  font-weight: 300;
}

.termos-de-uso-text044 {
  font-weight: 300;
}

.termos-de-uso-text045 {
  font-weight: 300;
}

.termos-de-uso-text046 {
  font-weight: 300;
}

.termos-de-uso-text047 {
  font-weight: 300;
}

.termos-de-uso-text048 {
  font-weight: 300;
}

.termos-de-uso-text049 {
  font-weight: 300;
}

.termos-de-uso-text050 {
  font-weight: 300;
}

.termos-de-uso-text051 {
  font-weight: 300;
}

.termos-de-uso-text052 {
  font-weight: 300;
}

.termos-de-uso-text053 {
  font-weight: 300;
}

.termos-de-uso-text054 {
  font-weight: 300;
}

.termos-de-uso-text055 {
  font-weight: 300;
}

.termos-de-uso-text056 {
  font-weight: 300;
}

.termos-de-uso-text057 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text058 {
  font-style: normal;
}

.termos-de-uso-text059 {
  font-weight: 500;
}

.termos-de-uso-text060 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text061 {
  font-style: normal;
}

.termos-de-uso-text062 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text063 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text064 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text065 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text066 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text067 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text068 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text069 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text070 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text071 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text072 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text073 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text074 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text075 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text076 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text077 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text078 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text079 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text080 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text081 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text082 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text083 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text084 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text085 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text086 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text087 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text088 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text089 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text090 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text091 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text092 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text093 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text094 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text095 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text096 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text097 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text098 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text099 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text100 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text101 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text102 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text103 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text104 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text105 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text106 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text107 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text108 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text109 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text110 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text111 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text112 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text113 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text114 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text115 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text116 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text117 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text118 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text119 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text120 {
  font-style: normal;
}

.termos-de-uso-text121 {
  font-weight: 500;
}

.termos-de-uso-text122 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text123 {
  font-style: normal;
}

.termos-de-uso-text124 {
  font-weight: 300;
}

.termos-de-uso-text125 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text126 {
  font-style: normal;
}

.termos-de-uso-text127 {
  font-weight: 500;
}

.termos-de-uso-text128 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text129 {
  font-style: normal;
}

.termos-de-uso-text130 {
  font-style: normal;
  font-weight: 300;
}

.termos-de-uso-text131 {
  font-weight: 300;
}

.termos-de-uso-text132 {
  font-weight: 300;
}

.termos-de-uso-text133 {
  font-weight: 300;
}

.termos-de-uso-text134 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text135 {
  font-style: normal;
}

.termos-de-uso-text136 {
  font-weight: 500;
}

.termos-de-uso-text137 {
  font-style: normal;
  font-weight: 400;
}

.termos-de-uso-text138 {
  font-style: normal;
}

.termos-de-uso-text139 {
  font-weight: 300;
}

.termos-de-uso-text140 {
  font-weight: 300;
}

.termos-de-uso-text141 {
  font-weight: 300;
}

.termos-de-uso-text142 {
  font-weight: 300;
}

.termos-de-uso-text143 {
  font-weight: 300;
}

.termos-de-uso-text144 {
  font-weight: 300;
}

.termos-de-uso-text145 {
  font-weight: 300;
}

.termos-de-uso-text146 {
  font-weight: 300;
}

.termos-de-uso-text147 {
  font-weight: 300;
}

.termos-de-uso-text148 {
  font-weight: 300;
}

.termos-de-uso-text149 {
  font-weight: 300;
}

.termos-de-uso-text150 {
  font-weight: 300;
}

.termos-de-uso-text151 {
  font-weight: 300;
}

.termos-de-uso-text152 {
  font-weight: 300;
}

.termos-de-uso-text153 {
  font-weight: 300;
}

.termos-de-uso-text154 {
  font-weight: 300;
}

.termos-de-uso-text155 {
  font-weight: 300;
}

.termos-de-uso-text156 {
  font-weight: 300;
}

@media(max-width: 991px) {
  .termos-de-uso-conteudo {
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .termos-de-uso-descricao {
    align-self: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media(max-width: 767px) {
  .termos-de-uso-text {
    font-size: 34px;
  }

  .termos-de-uso-text001 {
    font-size: 22px;
  }

  .termos-de-uso-descricao {
    padding: 20px;
  }

  .termos-de-uso-text002 {
    font-size: 28px;
  }

  .termos-de-uso-text003 {
    font-size: 13px;
  }
}

@media(max-width: 479px) {
  .termos-de-uso-text {
    font-size: 32px;
  }

  .termos-de-uso-text001 {
    font-size: 20px;
  }

  .termos-de-uso-conteudo {
    align-self: center;
  }
}