.container {
    gap: 30px;
    right: 20px;
    bottom: 40px;
    display: flex;
    position: fixed;
    border-radius: 5px;
    flex-direction: column;
    z-index:888888;
  }
  .conteudo {
    flex: 0 0 auto;
    width: 300px;
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    border-radius: 5px;
    flex-direction: column;
    background-color: #464646;
  }
  .head {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-top: 15px;
    padding-left: 20px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    justify-content: space-between;
    background-color: #e94910;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .text {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .icon {
    fill: white;
    width: auto;
    height: 14px;
    margin-top: -3px;
  }
  .body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .passo {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #5b5b5b;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .check {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-color: #19e604;
    border-width: 3px;
    border-radius: 50%;
    justify-content: center;
    background-color: #19e604;
  }
  .icon2 {
    fill: white;
    width: auto;
    height: 13px;
  }
  .text01 {
    color: #f1f1f1;
  }
  .passo1 {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #5b5b5b;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .check1 {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    border-color: #ababab;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
  }
  .text02 {
    color: #f1f1f1;
  }
  .passo2 {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #5b5b5b;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .check2 {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    border-color: #ababab;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
  }
  .text03 {
    color: #f1f1f1;
  }
  .passo3 {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #5b5b5b;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .check3 {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    border-color: #ababab;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
  }
  .text06 {
    color: #f1f1f1;
  }
  .passo4 {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #5b5b5b;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .check4 {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    border-color: #ababab;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
  }
  .text09 {
    color: #f1f1f1;
  }
  .passo5 {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    padding-left: 20px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .check5 {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    border-color: #ababab;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
  }
  .text12 {
    color: #f1f1f1;
  }
  .open-close-on-boarding {
    width: 45px;
    height: 45px;
    display: flex;
    align-self: flex-end;
    cursor:pointer;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon4 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .icon6 {
    fill: white;
    width: auto;
    height: 20px;
  }
  
  