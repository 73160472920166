.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
}
.login-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.login-logo {
  flex: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #212121;
}
.login-image {
  width: 60%;
  align-self: center;
  object-fit: cover;
}
.login-preencher {
  flex: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-image1 {
  width: 60%;
  display: none;
  align-self: center;
  object-fit: cover;
}
.login-container2 {
  gap: 30px;
  width: 100%;
  display: flex;
  padding: 30px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-text {
  color: white;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
}
.login-inputs {
  gap: 18px;
  width: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container3 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #212121;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.login-container3:hover {
  border-color: #3a3a3a;
}
.login-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.login-textinput {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.login-container4 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #212121;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.login-container4:hover {
  padding-top: 0px;
  border-color: #3a3a3a;
  padding-bottom: 0px;
}
.login-icon2 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.login-textinput1 {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 00px;
  padding-right: 00px;
  padding-bottom: 18px;
  background-color: transparent;
}
.login-text1 {
  color: #E86811;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  text-decoration: underline;
}
.login-text1:hover {
  color: #b7520e;
}
.login-container5 {
  gap: 18px;
  width: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-b-entrar {
  cursor:pointer;
  gap: 8px;
  width: 100%;
  display: flex;
  transition: 0.3s;
  padding-top: 12px;
  padding-left: 14px;
  border-radius: 10px;
  padding-right: 14px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #E86811;
}
.login-b-entrar.cadastro {
  background:#777;
}
.login-b-entrar:hover {
  background-color: #b7520e;
}
.login-b-entrar.cadastro:hover {
  background:#777;
  filter:brightness(1.3)
}
.login-icon4 {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}
.login-text2 {
  color: white;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
}
.login-container6 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.login-icon6 {
  fill: #E86811;
  width: 16px;
  height: 16px;
}
.login-text3 {
  color: rgb(185, 185, 185);
  cursor: pointer;
  font-size: 12px;
  align-self: center;
  text-decoration: underline;
}
@media(max-width: 991px) {
  .login-logo {
    display: none;
  }
  .login-preencher {
    position: relative;
    flex-direction: column;
  }
  .login-image1 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 120px;
    margin: auto;
    display: flex;
    padding: 30px;
    position: absolute;
    max-width:700px;
  }
}
@media(max-width: 767px) {
  .login-text {
    font-size: 34px;
  }
  .login-text1 {
    font-size: 13px;
  }
  .login-icon4 {
    width: 18px;
    height: 18px;
  }
  .login-text2 {
    font-size: 16px;
  }
  .login-icon6 {
    width: 14px;
    height: 14px;
  }
}
@media(max-width: 479px) {
  .login-image1 {
    width: 100px;
  }
  .login-text {
    font-size: 32px;
  }
  .login-inputs {
    width: 85%;
  }
  .login-icon {
    width: 22px;
    height: 22px;
  }
  .login-textinput {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .login-icon2 {
    width: 22px;
    height: 21px;
  }
  .login-textinput1 {
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .login-container5 {
    width: 85%;
  }
  .login-b-entrar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
