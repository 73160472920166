.tabela-aulas {
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 30px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #212121;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .btn-add-aula {
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    height: 45px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon {
    fill: white;
    width: auto;
    height: 20px;
  }
  .text01 {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .not-empty {
    width: 100%;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #3c3c3c;
  }
  .table {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
  }
  .container1 {
    gap: 28px;
    display: flex;
    padding-top: 20px;
    border-color: #3c3c3c;
    border-width: 2px;
    padding-left: 20px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 20px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .container2 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: flex-start;
  }
  .text02 {
    color: white;
    width: 42%;
    font-style: normal;
    font-weight: 500;
  }
  .text03 {
    color: white;
    width: 70px;
    font-style: normal;
    font-weight: 500;
    margin-left:auto;
    margin-right:28px;
  }
  .text04 {
    color: white;
    width: 22%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .container3 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: flex-start;
  }
  .infos {
    gap: 20px;
    display: flex;
    padding-top: 20px;
    border-color: #717171;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    padding-bottom: 15px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .container4 {
    gap: 28px;
    display: flex;
    position: relative;
    flex-direction: row;
  }
  .icon2 {
    fill: #ffffff;
    width: 16px;
    cursor: pointer;
    height: 16px;
    align-self: center;
    transition: 0.3s;
  }
  .icon2:hover {
    fill: #e4e4e4;
  }
  .container5 {
    gap: 8px;
    width: 70%;
    display: flex;
    flex-direction: row;
  }
  .image {
    width: 50px;
    height: 30px;
    min-width: 50px;
    min-height: 30px;
    object-fit: cover;
  }
  .text05 {
    color: white;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text08 {
    color: white;
    width: 70px;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left:auto;
    margin-right:28px;
  }

  .text08 input {
    width: 100%;
    background: #2c2c2c;
    color: #7e7e7e;
    border-radius: 20px;
    padding: 5px 0 5px;
    text-align: center;
    outline: none;
}
  .text09 {
    color: white;
    width: 22%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icon4 {
    top: 0px;
    fill: #fff;
    right: 0px;
    width: auto;
    bottom: 0px;
    cursor: pointer;
    height: 18px;
    margin: auto;
    position: absolute;
    align-self: center;
    transition: 0.3s;
  }
  .icon4:hover {
    fill: #e40000;
  }
  .paginao {
    gap: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    padding:15px 20px;
  }
  .text10 {
    color: white;
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
  }
  .text10:hover {
    font-style: normal;
    font-weight: 400;
  }
  .text11 {
    color: white;
    font-style: normal;
    font-weight: 500;
  }
  .text12 {
    color: white;
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
  }
  .text12:hover {
    font-style: normal;
    font-weight: 400;
  }
  @media(max-width: 767px) {
    .text {
      width: 40%;
      font-size: 15px;
    }
    .text01 {
      width: 40%;
      font-size: 15px;
    }
    .text02 {
      width: 40%;
      font-size: 15px;
    }
    .text03 {
      width: 60%;
      font-size: 15px;
    }
    .text04 {
      font-size: 15px;
    }
    .text05 {
      width: 40%;
      font-size: 15px;
    }
    .text06 {
      width: 40%;
      font-size: 15px;
    }
    .text08 {
      width: 60%;
      font-size: 15px;
    }
    .text09 {
      color: white;
      font-size: 15px;
    }
  }
  @media(max-width: 479px) {
    .infos {
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 12px;
    }
    .text05 {
      word-break: break-all;
    }
    .text06 {
      word-break: break-all;
    }
    .text08 {
      word-break: break-all;
    }
  }
  
  .filtros {
    display:flex;
    gap:20px;
  }

  .filtros .filtro {
    display:flex;
    flex-direction:column;
    gap:8px;
  }

  .filtros .filtro label {
    color:rgb(177, 177, 177);
  }

  .filtros .filtro:first-child {
    flex:1;
  }

  .filtros .filtro:first-child input {
    width:100%;
  }