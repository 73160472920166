.tabela-aulas {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 30px;
    position: relative;
    border-radius: 10px;
    flex-direction: column;
    padding-bottom: 30px;
    justify-content: flex-start;
    background-color: #212121;
  }
  .container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .container01 {
    gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .form {
    gap: 15px;
    width: 100%;
    display: flex;
    padding: 30px;
    max-width: 400px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #444444;
  }
  .container02 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text01 {
    color: white;
  }
  .text03 {
    color: #777777;
    font-size: 13px;
  }
  .container03 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text04 {
    color: white;
  }
  .container04 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text05 {
    color: white;
  }
  .container05 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text06 {
    color: white;
  }
  .container06 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text07 {
    color: white;
  }
  .btn-add {
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    height: 45px;
    display: flex;
    position: relative;
    align-self: center;
    margin-top: 20px;
    align-items: center;
    padding-top: 25px;
    padding-left: 40px;
    border-radius: 10px;
    padding-right: 40px;
    flex-direction: row;
    padding-bottom: 25px;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon {
    fill: white;
    width: auto;
    height: 20px;
  }
  .text08 {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .container07 {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container08 {
    gap: 8px;
    width: 300px;
    cursor: pointer;
    display: flex;
    align-self: center;
    transition: 0.3s;
    align-items: center;
    padding-top: 10px;
    padding-left: 8px;
    border-radius: 10px;
    padding-right: 8px;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: flex-start;
    background-color: #323232;
  }
  .container08:hover {
    background-color: #212121;
  }
  .container09 {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
  }
  .image {
    width: 40px;
    height: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  .container10 {
    right: -2px;
    width: 20px;
    bottom: -2px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-primary-300);
  }
  .icon2 {
    fill: #ffffff;
    width: 16px;
    height: 16px;
    align-self: center;
  }
  .container11 {
    gap: 4px;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
  }
  .text09 {
    color: rgb(255, 255, 255);
  }
  .text10 {
    color: rgb(185, 185, 185);
    cursor: pointer;
    font-size: 12px;
  }
  .container12 {
    flex: 0 0 auto;
    width: 8px;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 8px;
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: rgb(255, 91, 28);
  }
  .stats {
    gap: 30px;
    height: fit-content;
    display: flex;
    padding: 30px;
    box-shadow: 0px 0px 6px 0px #500448;
    align-items: flex-start;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    background-color: #b92faa;
  }
  .icon4 {
    fill: #800a73;
    width: auto;
    height: 70px;
    align-self: center;
  }
  .text11 {
    color: white;
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    align-self:center;
    line-height: 1.2;
  }
  .text14 {
    font-size: 60px;
  }
  @media(max-width: 767px) {
    .text {
      width: 40%;
      font-size: 15px;
    }
    .text08 {
      width: 40%;
      font-size: 15px;
    }
    .text11 {
      width: 40%;
      font-size: 15px;
    }
    .text12 {
      width: 40%;
      font-size: 15px;
    }
  }
  
  
  .input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }

  .container3 {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .label1 {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .container4 {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .container3 .image {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .fechar-popup1 {
    top: 50%;
    right: -12px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon2 {
    fill: #fff;
    width: auto;
    height: 12px;
  }

  .home-g-aula-mensal {
    display: flex;
    flex-direction: column;
  }
  .home-aula-mensal {
    gap: 20px;
    width: 400px;
    border: 1px solid #3c3c3c;
    height: 160px;
    display: none;
    padding: 20px;
    position: relative;
    background: #212121;
    margin-top: 30px;
    align-items: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container16 {
    gap: 8px;
    height: 100%;
    display: flex;
    max-width: 50%;
    flex-direction: column;
  }
  .home-text33 {
    color: white;
    width: fit-content;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 5px;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .home-text36 {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text39 {
    color: #909090;
    font-size: 14px;
  }
  .home-container17 {
    gap: 3px;
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
  }
  .home-image212 {
    width: 50px;
    height: 50px;
    border-color: rgba(240, 240, 240, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: 41.5px;
    object-fit:cover;
    object-position:center;
  }
  .home-image213 {
    width: 50px;
    height: 50px;
    border-color: rgba(240, 240, 240, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: 41.5px;
  }
  .home-image222 {
    width: 50px;
    height: auto;
    border-color: rgba(240, 240, 240, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: 41.5px;
  }
  .home-image223 {
    width: 50px;
    height: auto;
    border-color: rgba(240, 240, 240, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: 41.5px;
  }