.container {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 30px;
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;
  justify-content: flex-start;
  background-color: #212121;
}

.content {
  width: 100%;
  margin: 0px auto;
}

.secaoTitulo {
  color: white;
  font-size: 50px;
  margin-top: 20px;
}

.indicacaoCaminho {
  font-size: 15px;
  color: #ccc;
}

.paginasGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}


.pagina {
  position: relative;
  background: rgb(50, 50, 50);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.1s ease-in-out;
  color: white;
  cursor: pointer;
}

.pagina::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}


.pagina:hover {
  transform: scale(1.01);
}

.pagina:active {
  transform: scale(1.00);
}

.paginaTitulo,
.paginaDescricao {
  padding: 25px;
  position: relative;
  z-index: 2;
}

.paginaTitulo {
  font-size: 20px;
  font-weight: bold;
}

.paginaDescricao {
  font-size: 16px;
}

.opcaoVoltar {
  color: #e86811;
  cursor: pointer;
  margin: 5px 0px;
  transition: margin-left 0.1s linear;
}

.opcaoVoltar:hover {
  margin-left: 5px;
}

.opcaoVoltar:active {
  color: #787878;
}

.semOpcoes {
  font-size: 25px;
  text-align: center;
  color: rgb(100, 100, 100);
  padding: 20px 0px;
}











.input-master,
.textarea-master {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
  background-color: #3b3b3b;
  color: white;
}

.input-master:focus,
.textarea-master:focus {
  border-color: orange;
  outline: none;
}

.textarea-master {
  resize: vertical;
  min-height: 100px;
}

.submit-master {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: orange;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-master:hover {
  background-color: grey;
}

.submit-master:active {
  background-color: darkgrey;
}

.error-master {
  color: red;
  font-size: 14px;
}


.page-title-input {
  margin-bottom: 20px;
}

.banner-container {
  width: 100%;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* Adicione esta linha */
}

.banner-image {
  width: 75%;
  height: 375px;
  /* 4:3 proportion */
  border: 2px solid #e86811;
  box-shadow: 0px 0px 10px #e86811;
  border-radius: 10px;
  object-fit: cover;
  transition: opacity 0.3s ease;
  margin: 5px 0px 20px 0px;
}

.banner-input {
  display: none;
}

.banner-text {
  text-align: center;
  color: white;
  font-size: 30px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.banner-text:hover {
  color: #e86811;
}

.embrevelabel {
  color: white;
  line-height: 30px;
  float: left;
  margin-right: 10px;
}


.salvarUpdate {
  padding: 10px;
  background-color: #e86811;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.salvarUpdate:hover {
  background-color: #333333;
}

.salvarUpdate:active {
  background-color: #212121;
}


.secaoTitulo {
  font-size: 30px;
  color: #ddd;
  margin: 50px 0px 25px 0px;
}

.tabela {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabela th,
.tabela td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.tabela th {
  background-color: #1a1a1a;
  /* Cor mais escura para o cabeçalho */
  color: #f2f2f2;
  /* Cor de texto clara para contraste */
}

.tabela td {
  background-color: #333333;
  /* Cor mais escura para as células */
  color: #f2f2f2;
  /* Cor de texto clara para contraste */
  user-select: none;
}

.tabela tbody tr:nth-child(even) {
  background-color: #393939;
  /* Cor mais clara para linhas alternadas */
}

.tabela tbody tr:not(:last-child) {
  cursor: pointer;
}

.tabela tbody tr:not(:last-child):hover {
  background-color: #3a3a3a;
  /* Cor de fundo ao passar o mouse */
}

.tabela tbody tr:not(:last-child):active {
  background-color: #383838;
  /* Cor de fundo ao clicar */
}

.checkbox-master {
  appearance: none;
  background-color: #ccc;
  border: 2px solid #333333;
  padding: 10px;
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.checkbox-master:checked {
  background-color: #e86811;
}

.checkbox-master:checked:after {
  content: '';
  position: absolute;
  display: block;
  right: 7px;
  top: 5px;
  width: 4px;
  height: 8px;
  border: solid #212121;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.btnIcone {
  background-color: #333333;
  /* Cor do botão */
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* Espaçamento entre os botões */
  width: 40px;
  height: 40px;
}

.btnIcone:hover {
  background-color: #444444;
  /* Cor de fundo ao passar o mouse */
}

.btnIcone:active {
  background-color: #555555;
  /* Cor de fundo ao clicar */
}