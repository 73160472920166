.meu-perfil-container {
  gap: 5px;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0f0f0f;
}
.meu-perfil-container01 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  padding-top:65px;
}

@media screen and (max-width:768px) {
  .meu-perfil-container01 {
    padding-top:0;
  }
}
.meu-perfil-conteudo {
  gap: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom:20px;
  flex-direction: column;
  justify-content: flex-start;
}
.meu-perfil-container02 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.meu-perfil-icon {
  fill: white;
  width: 24px;
  height: 24px;
}
.meu-perfil-text {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
}
.meu-perfil-container03 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.meu-perfil-opcoes-perfil {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.meu-perfil-text01 {
  color: #E86811;
  width: auto;
  cursor: pointer;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #212121;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-text02 {
  color: white;
  width: auto;
  cursor: pointer;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #212121;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container04 {
  gap: 20px;
  flex: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px;
  position: relative;
  align-self: center;
  background: #212121;
  margin-right: 0px;
  border-radius: 10px;
  flex-direction: column;
  border-top-left-radius: 0;
}
.meu-perfil-contedo {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.meu-perfil-dados-pessoais {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.meu-perfil-container05 {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.meu-perfil-image {
  width: 100px;
  height:100px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.meu-perfil-text03 {
  color: rgb(185, 185, 185);
  cursor: pointer;
  font-size: 12px;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-weight: 300;
  text-decoration: underline;
}
.meu-perfil-text03:hover {
  color: #ffffff;
}
.meu-perfil-inputs {
  gap: 18px;
  width: 100%;
  display: flex;
  max-width: 500px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-container06 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-text04 {
  color: white;
  width: auto;
  padding: 0px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container07 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.meu-perfil-container07:hover {
  border-color: #4a4a4a;
}
.meu-perfil-icon02 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.meu-perfil-textinput {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.meu-perfil-container08 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-text07 {
  color: white;
  width: auto;
  padding: 0px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container09 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.meu-perfil-container09:hover {
  border-color: #4a4a4a;
}
.meu-perfil-icon04 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.meu-perfil-textinput1 {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.meu-perfil-container10 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-text08 {
  color: white;
  width: auto;
  padding: 0px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container11 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 0px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
  background-color: #292929;
}
.meu-perfil-icon06 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.meu-perfil-textinput2 {
  color: #cacaca;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.meu-perfil-alterar-senha {
  display:flex;
  gap: 18px;
  width: 100%;
  max-width: 500px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-container12 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-text11 {
  color: white;
  width: auto;
  padding: 0px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container13 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.meu-perfil-container13:hover {
  border-color: #4a4a4a;
}
.meu-perfil-icon08 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.meu-perfil-textinput3 {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.meu-perfil-container14 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meu-perfil-text12 {
  color: white;
  width: auto;
  padding: 0px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.meu-perfil-container15 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.meu-perfil-container15:hover {
  border-color: #4a4a4a;
}
.meu-perfil-icon10 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.meu-perfil-textinput4 {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}
.meu-perfil-b-salvar {
  gap: 8px;
  display: flex;
  align-self: center;
  transition: 0.3s;
  padding-top: 12px;
  padding-left: 14px;
  border-radius: 10px;
  padding-right: 14px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #E86811;
}
.meu-perfil-b-salvar:hover {
  background-color: #b7520e;
}
.meu-perfil-icon12 {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}
.meu-perfil-text13 {
  color: white;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
  cursor:pointer;
}
@media(max-width: 991px) {
  .meu-perfil-conteudo {
    padding-left: 20px;
  }
}
@media(max-width: 767px) {
  .meu-perfil-icon {
    width: 22px;
    height: 22px;
  }
  .meu-perfil-text {
    font-size: 24px;
  }
  .meu-perfil-text01 {
    font-size: 14px;
  }
  .meu-perfil-text02 {
    font-size: 14px;
  }
  .meu-perfil-b-salvar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .meu-perfil-icon12 {
    width: 18px;
    height: 18px;
  }
  .meu-perfil-text13 {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .meu-perfil-contedo {
    gap: 30px;
  }
  .meu-perfil-image {
    width: 85px;
  }
  .meu-perfil-icon02 {
    width: 22px;
    height: 22px;
  }
  .meu-perfil-textinput {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .meu-perfil-icon04 {
    width: 22px;
    height: 22px;
  }
  .meu-perfil-textinput1 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .meu-perfil-icon06 {
    width: 22px;
    height: 22px;
  }
  .meu-perfil-textinput2 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .meu-perfil-icon08 {
    width: 22px;
    height: 22px;
  }
  .meu-perfil-textinput3 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .meu-perfil-textinput4 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
