:root {
  --primary-outter-background: #0f0f0f;
  --primary-background: #212121;
  --primary-content: #212121;
  --primary-border: #3c3c3c;
  --text-color: #a1a1a1;
  --hover-text-color: #c8c8c8;
  --highlight-color: #e86817;
  --secondary-border: #646464;
  --tertiary-border: #008000;
}

.table-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background-color: #212121;
  color: var(--text-color)
}

.table-header {
  width: 100%;
  text-align: center;
}

.table-heading {
  color: white;
  font-size: 28px;
  font-weight: 500;
}

.table-content {
  width: 100%;
  overflow-x: auto; /* Adicionado para tornar a tabela horizontalmente responsiva */
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #3c3c3c;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #3c3c3c;
  color: white;
  font-weight: 500;
}

.btn-ver-diagnostico {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-ver-diagnostico svg {
  fill: #a1a1a1;
  cursor: pointer;
}

.btn-ver-diagnostico svg:hover {
  fill: #c8c8c8;
}

.btn-ver-diagnostico svg:active {
  fill: #e86817;
}

.empty-table {
  margin-top: 20px;
  color: white;
  text-align: center;
}


  @media(max-width: 767px) {
    .text,
    .text01,
    .text05,
    .text06,
    .text07,
    .text08,
    .text09,
    .text10,
    .text11,
    .text12,
    .text14 {
      width: 100%;
      font-size: 15px;
    }
  }
  @media(max-width: 479px) {
    .infos {
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 12px;
    }
    .text09 {
      word-break: break-all;
    }
    .text10 {
      word-break: break-all;
    }
    .text11 {
      word-break: break-all;
    }
    .text12 {
      word-break: break-all;
    }
  }

  .input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }