:root {
    --primary-outter-background: #0f0f0f;
    --primary-background: #212121;
    --primary-content: #212121;
    --primary-border: #3c3c3c;
    --hover-text-color: #c8c8c8;
    --highlight-color: #e86817;
    --secondary-border: #646464;
    --tertiary-border: #008000;
}

.diagnostico-container {
    gap: 5px;
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary-outter-background);
}

.diagnostico-area {
    gap: 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 65px;
}

.diagnostico-panel {
    gap: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

.diagnostico-content {
    width: 100%;
    background: var(--primary-background);
    border-radius: 10px;
    padding: 30px;
    border-width: 2px;
    border: none;
    padding: 0px;
    overflow: hidden;
}

h1.page-title {
    display: block;
    overflow: hidden;
    /*background-image: url('https://wallpapers.com/images/featured/cool-orange-background-e1qikoe3q6zbwhzu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;*/
    background-color: var(--highlight-color);
    color: white;
    font-size: 40px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 20px;
    text-align: left;
    margin-bottom: 50px;
}

.novo-diagnostico{
    text-align: center;
    color:rgb(200,200,200);
    padding: 20px 0px;
}

.novo-diagnostico:hover{
    background-color: rgb(50,50,50);
    cursor: pointer;
}

.novo-diagnostico:active{
    background-color: rgb(25,25,25);
    cursor: pointer;
}

.diagnostico-grafico-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
    justify-content: center; /* Centraliza os gráficos horizontalmente */
    align-items: center; /* Centraliza os gráficos verticalmente */
  }
  
.diagnostico-grafico {
    width: 100%;
    min-width: 0; /* Forçar overflow em telas menores */
}
    

/* Table container */
.tabela-diagnosticos {
    width: 400px;
    margin:20px auto; 
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    font-family: sans-serif;
    font-size: 14px;
    color: #212121;
  }
  
  /* Header row */
  .tabela-diagnosticos thead th {
    background-color: rgb(50,50,50);
    color: #c8c8c8;
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #3c3c3c;
  }
  
  /* Body rows */
  .tabela-diagnosticos tbody tr {
    background-color: rgb(200,200,200);
    border-bottom: 1px solid #646464;
  }
  
  .tabela-diagnosticos tbody tr:nth-child(even) {
    background-color: rgb(150,150,150); /* Alternating row colors for visual distinction */
  }
  
  .tabela-diagnosticos tbody td {
    padding: 12px 15px;
    text-align: center;
    vertical-align: top;
  }
  
  /* Actions column */
  .tabela-diagnosticos tbody td:last-child {
    text-align: center;
  }
  
  /* Buttons */
  .tabela-diagnosticos tbody td button {
    background-color: #e86817;
    border: none;
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
  }
  
  .tabela-diagnosticos tbody td button:hover {
    background-color: #d0570d;
  }
  


.diagnostico-info-container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.diagnostico-info {
  box-sizing: border-box;
  flex-basis: calc(25% - 10px); /* 4 items per row with margin */
  margin-bottom: 10px;
  background-color: var(--primary-border); /* Cor verde */
  padding: 20px;
  color: white;
  font-size: 25px;
  line-height: 35px;
  border-radius: 10px;
}

.info-sem-diagnostico{
  color: var(--hover-text-color);
  text-align: center;
  padding: 25px 10px;
  font-size: 20px;
}

span.usuario-nome{
  text-decoration: underline;
}

.info-sem-diagnostico-voltar{
  max-width: 100px;
  color:#e86817;
  text-align: center;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 20px auto;
  cursor: pointer;
}

.info-sem-diagnostico-voltar:hover{
  color: white;
  background-color: #e86817;
}

.info-sem-diagnostico-voltar:active{
  color: var(--hover-text-color);
  background-color: #d0570d;
}


/* Estilo para mobile (um em cima do outro) */
@media screen and (max-width: 480px) {
  .diagnostico-info {
    flex-basis: 100%; /* 1 item per row with margin */
  }
}

@media screen and (max-width: 768px) {
  .diagnostico-grafico-container {
    grid-template-columns: 1fr;
  }

  .diagnostico-info {
    flex-basis: calc(50% - 10px); /* 2 items per row with margin */
  }
}

@media screen and (max-width: 991px) {
  .diagnostico-area {
      padding-top: 0;
  }

}