.container {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.89);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index:9999999999;
}
.container1 {
  width: 90%;
  display: flex;
  max-width: 600px;
  background: #3b3b3b;
  border-radius: 10px;
  flex-direction: column;
}
.head {
  display: flex;
  padding: 20px 30px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.text {
  color: rgb(243, 243, 243);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
.fechar-popup {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
}
.icon {
  fill: #3b3b3b;
  width: 18px;
  cursor: pointer;
  height: 18px;
  transition: 0.3s;
}
.icon:hover {
  filter: brightness(1.3);
}
.opcoes {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px 30px;
  overflow: auto;
  flex-wrap: wrap;
  border-top: 1px solid #575757;
  max-height: 50vh;
  align-items: stretch;
  padding-left: 20px 30px;
  flex-direction: row;
  justify-content: center;
}
.btn-option {
  gap: 5px;
  flex: 1;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #1d1d1d;
  cursor:pointer;
}
.container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.icon2 {
  fill: #0cda07;
  width: 24px;
  height: 24px;
}
.text01 {
  color: var(--dl-color-default-laranjaapp);
  font-style: normal;
  font-weight: 500;
}
.text02 {
  color: rgb(117, 117, 117);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
}
.text05 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}
.btn-option1 {
  gap: 5px;
  flex: 1;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #1d1d1d;
  cursor:pointer;
}
.container4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.icon4 {
  fill: #0cda07;
  width: 24px;
  height: 24px;
}
.text06 {
  color: var(--dl-color-default-laranjaapp);
  font-style: normal;
  font-weight: 500;
}
.text07 {
  color: rgb(117, 117, 117);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
}
.text10 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}
.text11 {
  color: #6b6b6b;
  font-size: 15px;
  text-decoration: line-through;
}
.btn-option2 {
  gap: 5px;
  flex: 1;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #1d1d1d;
  cursor:pointer;
}
.container6 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container7 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.icon6 {
  fill: #0cda07;
  width: 24px;
  height: 24px;
}
.text13 {
  color: var(--dl-color-default-laranjaapp);
  font-style: normal;
  font-weight: 500;
}
.text14 {
  color: rgb(117, 117, 117);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
}
.text17 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}
.text18 {
  color: #6b6b6b;
  font-size: 15px;
  text-decoration: line-through;
}
@media(max-width: 479px) {
  .opcoes {
    flex-direction: column;
  }
}
