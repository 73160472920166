.indicacao-container {
  gap: 50px;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #0f0f0f;
}
.indicacao-image {
  width: 300px;
  margin-top: -80px;
  object-fit: cover;
}
.indicacao-container1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 30px;
  max-width: 600px;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  background-color: #212121;
}
.indicacao-container2 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.indicacao-text {
  color: white;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
}
.indicacao-text03 {
  color: rgb(228, 228, 228);
  font-size: 16px;
  text-align: center;
}
.indicacao-text05 {
  font-weight: 700;
}
.indicacao-text07 {
  color: #e4e4e4;
  font-size: 16px;
  text-align: center;
}
.indicacao-text08 {
  color: #e4e4e4;
  font-size: 16px;
  text-align: center;
}
.indicacao-b-continuar-aula {
  width: fit-content;
  height: 45px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  padding-top: 0px;
  padding-left: 24px;
  border-radius: 10px;
  padding-right: 24px;
  flex-direction: row;
  padding-bottom: 0px;
  background-color: #E86811;
  cursor:pointer;
}
.indicacao-b-continuar-aula:hover {
  background-color: #b7520e;
}
.indicacao-text09 {
  color: white;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 767px) {
  .indicacao-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .indicacao-text09 {
    font-size: 16px;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .indicacao-container {
    gap: 30px;
  }
  .indicacao-image {
    width: 80%;
    margin-top: -50px;
  }
  .indicacao-text {
    font-size: 24px;
  }
  .indicacao-text03 {
    font-size: 14px;
  }
  .indicacao-text07 {
    font-size: 14px;
  }
  .indicacao-text08 {
    font-size: 14px;
  }
  .indicacao-b-continuar-aula {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }
  .indicacao-text09 {
    font-size: 14px;
  }
}
