#aside-desktop,
#aside-mobile {
  gap: 25px;
  display: flex;
  padding: 0px 0px 0px 0px;
  /*position: fixed;
  top: 100px;
  */
  margin-top: 100px;
  width: 320px;
  background: #212121;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 100px);
  overflow: hidden;
  z-index: 20;
}

.aside-overflow {
  height: fit-content;
  overflow: auto;
}

.aside-container {
  gap: 10px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.aside-container .box-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 10px 0px;
}

.aside-container .box-control button {
  cursor: pointer;
  margin: 0px 10px;
}

.aside-container .box-control button:hover svg {
  fill: #E86811;
}

.subtitulo-menu {
  width: 100%;
  padding: 20px;
  background-color: rgb(25, 25, 25);
}

.subtitulo-menu h3 {
  color: #fff;
  font-size: 25px;
  margin-bottom: 7px;
}

.subtitulo-menu p {
  color: #c8c8c8;
  font-size: 12px;
}

.em-breve-menu {
  background-color: #ff4800;
  border-radius: 5px;
  padding: 3px;
  color: rgb(240, 240, 240);
  margin-left: 10px;
  font-size: 10px;
}

@media screen and (max-width:768px) {
  .aside-container .box-control>button:first-child {
    position: absolute;
    left: 0;
  }

  .aside-container .box-control>div:last-child {
    position: absolute;
    right: 0;
  }
}

.activePage>div {
  border-left: 4px solid #E86811;
}

.activePage span {
  color: #E86811 !important;
}

.activePage svg {
  fill: #E86811 !important;
}

.aside-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.aside-text {
  color: rgb(240, 240, 240);
}

.aside-container1 {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  margin-bottom: 5px;
}

.aside-text1 {
  color: rgb(240, 240, 240);
  font-size: 14px;
}

.aside-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 8px;
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #343434;
}

.aside-container3 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px 0 0 10px;
  background-color: #ff4800;
}

.aside-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.aside-navlink {
  display: contents;
}

.aside-container4 {
  gap: 15px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  border-bottom: 2px solid #3c3c3c;
  flex-direction: row;
  border-left: 4px solid transparent;
  text-decoration: none;
}


.aside-container4 svg {
  width: 18px;
}

.aside-container4:hover {
  border-left: 4px solid #E86811
}

.aside-container4:hover .aside-icon {
  fill: #E86811;
}

.aside-container4:hover .aside-text2 {
  color: #E86811;
}

.aside-icon {
  fill: rgb(240, 240, 240);
  width: auto;
  height: 18px;
  cursor: pointer;
}

.aside-text2 {
  color: rgb(240, 240, 240);
  font-size: 14px;
  line-height: 1;
}

.aside-navlink1 {
  display: contents;
}

.aside-container5 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  border-bottom: 2px solid #3c3c3c;
  flex-direction: row;
  text-decoration: none;
}

.aside-icon02 {
  fill: rgb(240, 240, 240);
  width: auto;
  height: 18px;
}

.aside-text3 {
  color: rgb(240, 240, 240);
  font-size: 14px;
  line-height: 1;
}

.aside-navlink2 {
  display: contents;
}

.aside-container6 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  border-bottom: 2px solid #3c3c3c;
  flex-direction: row;
  text-decoration: none;
}

.aside-icon04 {
  fill: rgb(240, 240, 240);
  width: auto;
  height: 18px;
}

.aside-text4 {
  color: rgb(240, 240, 240);
  font-size: 14px;
  line-height: 1;
}

.aside-navlink3 {
  display: contents;
}

.aside-container7 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  padding-left: 15px 20px;
  border-bottom: 2px solid #3c3c3c;
  flex-direction: row;
  text-decoration: none;
}

.aside-icon06 {
  fill: rgb(240, 240, 240);
  width: auto;
  height: 18px;
}

.aside-text5 {
  color: rgb(240, 240, 240);
  font-size: 14px;
  line-height: 1;
}

.aside-container8 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  flex-direction: row;
}

.aside-icon08 {
  fill: rgb(240, 240, 240);
  width: auto;
  height: 18px;
}

.aside-text6 {
  color: rgb(240, 240, 240);
  font-size: 14px;
  line-height: 1;
}

.aside-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
}

.aside-navlink4 {
  color: #6d6d6d;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.aside-navlink5 {
  color: #6d6d6d;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.aside-text7 {
  color: #6d6d6d;
  font-size: 13px;
}

.aside-root-class-name {
  min-width: 320px;
}

.aside-root-class-name1 {
  min-width: 320px;
}

.aside-root-class-name2 {
  min-width: 320px;
}

.aside-root-class-name3 {
  min-width: 320px;
}

.aside-root-class-name4 {
  min-width: 320px;
}

.aside-root-class-name5 {
  align-self: flex-start;
}

.aside-root-class-name6 {
  min-width: 320px;
}

#aside-desktop {
  display: flex;
}

#aside-mobile {
  display: none;
}

@media(max-width: 991px) {

  #aside-desktop,
  #aside-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: 0px;
    padding-top: 20px;
    position: fixed;
    z-index: 99999999999999999999999999999999999999999999;
    overflow: auto;
    height: 100vh;
    min-height: 100vh;
  }

  #aside-desktop {
    display: none;
  }

  #aside-mobile {
    display: flex;
  }

  .aside-overflow {
    width: 100%;
    height: 100%
  }
}

.credit-info {
  background: #171717;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border-radius: 10px;
}

.credit-info svg {
  height: 16px;
  width: auto;
}

.credit-info span {
  font-size: 12px;
  font-weight: 500;
  color: #9e9e9e;
}

.credit-info strong {
  color: #E86811;
}