.shorts-container {
  gap: 5px;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
}

.shorts-conteudo {
  gap: 30px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-right: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.shorts-box {
  gap: 32px;
  flex: 0;
  width: 100%;
  display: flex;
  padding: 30px;
  position: relative;
  background: #212121;
  margin-top: 15px;
  margin-right: 0px;
  border-radius: 10px;
  flex-direction: column;
}

.shorts-container1 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 100px;
}

.shorts-box .head {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shorts-box .head .titulo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.shorts-box .head .titulo .shorts-icon {
  height: 24px;
  width: auto;
  fill: white;
}

.shorts-box .head .titulo span {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
}

.shorts-box .head>span {
  color: #9f9f9f;
  line-height: 1.4;
  max-width: 800px;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  height: 40px;
  cursor: pointer;
}

.dropdown-header {
  height: 100%;
  padding: 10px;
  cursor: pointer;
  background-color: #6a6a6a;
  border-radius: 10px;
  font-size: 14px;
  color: #c6c6c6;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #4a4a4a;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 10px 10px;
  max-height: 200px;
  overflow: auto;
}

.dropdown-content label {
  padding: 10px;
  cursor: pointer;
  color: #9e9e9e;
  font-size: 14px;
  border-bottom: 1px solid #646464;
  display: flex;
  align-items: center;
}

.dropdown-content label:last-child {
  border-bottom: none;
}

.dropdown-content input {
  margin-right: 6px;
  outline: none;
  height: 14px;
  width: 14px;
}

.dropdown-content label:hover {
  color: white;
  background-color: #E86811;
}

.filtros {
  display: flex;
  gap: 24px;
}

.filtros .filtro {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.filtros .filtro:first-child {
  flex: 4
}

.filtros .filtro .label {
  color: #8e8e8e;
}

.info {
  display: flex;
  gap: 6px;
  align-items: center;
}

.info>div {
  width: 8px;
  height: 8px;
  background: #0cda07;
  border-radius: 50%;
}

.info p {
  color: white;
}

.shorts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.tag-novo {
  background: blue;
  position: absolute;
  right: 0;
  color: white;
  padding: 6px;
  border-radius: 0 10px 0 0;
}

.short {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.short img {
  width: 100%;
  border-radius: 10px;
}

.tags {
  display: flex;
  gap: 5px;
}

.tags .tag {
  color: white;
  font-size: 13px;
  font-weight: 500;
  background: #E86811;
  padding: 5px 10px;
  border-radius: 10px;
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 85px;
  overflow: hidden;
}

@media screen and (max-width:991px) {
  .shorts-container1 {
    padding-top: 0;
  }
}

@media screen and (max-width:479px) {
  .filtros {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

  .shorts-box {
    gap: 20px;
  }

  .shorts-conteudo {
    padding: 20px;
  }

  .shorts-box .head .titulo span {
    font-size: 21px;
  }

  .shorts-box .head .titulo .shorts-icon {
    height: 20px;
    width: auto;
  }
}

.btn-vermais {
  background: #6f6f6f;
  padding: 0 2rem;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  width: fit-content;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 1rem;
  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 479px) {
  .shorts {
    gap: 0;
    row-gap: 15px;
    justify-content: space-between;
  }

  .shorts .short {
    width: 31%;
  }

  .shorts .short .tags {
    display: none;
  }
}