.container {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.89);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index:99999999999999;
  }
  .container1 {
    width: 90%;
    display: flex;
    max-width: 600px;
    background: #3b3b3b;
    border-radius: 10px;
    flex-direction: column;
  }
  .head {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: rgb(243, 243, 243);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .fechar-popup {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
  }
  .icon {
    fill: #3b3b3b;
    width: 18px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;
  }
  .icon:hover {
    filter: brightness(1.3);
  }
  .form {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    overflow: auto;
    border-top: 1px solid #575757;
    max-height: 50vh;
    align-items: flex-start;
    border-bottom: 1px solid #575757;
    flex-direction: column;
  }
  .descricao {
    color: rgb(243, 243, 243);
    font-style: normal;
    font-weight: 400;
  }
  .container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn-cancelar {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    background: rgb(100 100 100);
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: rgb(71 71 71);
  }
  .btn-cancelar:hover {
    filter: brightness(1.3);
  }
  .btn-excluir {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    height: 45px;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-left: 24px;
    border-radius: 10px;
    padding-right: 24px;
    background-color: #e80606;
  }
  .btn-excluir:hover {
    filter: brightness(1.3);
  }
  