.meus-colaboradores-container {
  gap: 5px;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
}

.meus-colaboradores-container01 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 100px;
}

@media screen and (max-width:991px) {
  .meus-colaboradores-container01 {
    padding-top: 0;
  }
}

.meus-colaboradores-conteudo {
  gap: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.meus-colaboradores-container02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.meus-colaboradores-container03 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  padding-right: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.meus-colaboradores-container04 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.meus-colaboradores-icon {
  fill: white;
  width: 24px;
  height: 24px;
}

.meus-colaboradores-text {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-text01 {
  color: rgb(185, 185, 185);
  font-size: 14px;
}

.meus-colaboradores-creditos {
  gap: 24px;
  display: flex;
  flex-direction: row;
}

.meus-colaboradores-text06 {
  color: white;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}

.meus-colaboradores-text07,
.meus-colaboradores-comprar-txt {
  color: #ff4800;
  cursor: pointer;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 500;
}

.meus-colaboradores-text07:hover,
.meus-colaboradores-comprar-txt:hover {
  color: #b7520e;
}

.meus-colaboradores-comprar-txt {
  color: white;
  font-size: 15px;
}

.meus-colaboradores-container05 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.meus-colaboradores-b-continuar-aula {
  width: fit-content;
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  padding-top: 12px;
  padding-left: 14px;
  border-radius: 10px;
  padding-right: 14px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: flex-end;
  background-color: #E86811;
  cursor: pointer;
}

.meus-colaboradores-b-continuar-aula:not([disabled]):hover {
  background-color: #b7520e;
}

.meus-colaboradores-text08 {
  color: white;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-colaboradores {
  gap: 50px;
  flex: 0;
  width: 100%;
  height: auto;
  display: flex;
  padding: px;
  position: relative;
  background: #212121;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}

.meus-colaboradores-container06 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.meus-colaboradores-container07 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meus-colaboradores-container08 {
  gap: 28px;
  display: flex;
  padding: 30px;
  border-color: #3c3c3c;
  border-width: 2px;
  padding-left: 30px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.meus-colaboradores-text09 {
  color: white;
  width: 25%;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-text10 {
  color: white;
  width: 40%;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-text11 {
  color: white;
  width: 30%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-header-deletar {
  color: white;
  width: 5%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-infos {
  gap: 20px;
  display: flex;
  padding-top: 24px;
  padding-left: 30px;
  border-radius: 10px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 24px;
  background-color: #212121;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.meus-colaboradores-container09 {
  gap: 28px;
  display: flex;
  flex-direction: row;
}

.meus-colaboradores-text12 {
  color: white;
  width: 25%;
  font-size: 16px;
}

.meus-colaboradores-text13 {
  color: white;
  width: 40%;
  font-size: 16px;
}

.meus-colaboradores-text14 {
  color: white;
  width: 30%;
  font-size: 16px;
}

.meus-colaboradores-body-deletar {
  color: #ff4800;
  width: 5%;
  font-size: 16px;
  cursor: pointer;
}

.meus-colaboradores-body-deletar:hover {
  color: red;
}

.meus-colaboradores-body-deletar:active {
  color: hsl(30, 80%, 50%);
}

.meus-colaboradores-infos1 {
  gap: 20px;
  display: flex;
  padding-top: 24px;
  padding-left: 30px;
  border-radius: 10px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 24px;
  background-color: #212121;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.meus-colaboradores-text15 {
  color: white;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.meus-colaboradores-container10 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-end;
  padding-top: 24px;
  border-color: #3c3c3c;
  border-width: 2px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 30px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.meus-colaboradores-text16 {
  color: white;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}

.meus-colaboradores-text16:hover {
  font-style: normal;
  font-weight: 400;
}

.meus-colaboradores-text17 {
  color: white;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-text18 {
  color: white;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
}

.meus-colaboradores-text18:hover {
  font-style: normal;
  font-weight: 400;
}

.meus-colaboradores-popup-agendamento {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.meus-colaboradores-conteudo1 {
  gap: 28px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: 20px;
  display: flex;
  padding: 30px;
  position: relative;
  max-width: 500px;
  align-self: center;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #212121;
}


.meus-colaboradores-container11 {
  gap: 8px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.meus-colaboradores-navlink {
  display: contents;
}

.meus-colaboradores-logo {
  width: 40px;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}

.meus-colaboradores-text19 {
  color: white;
  font-size: 26px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-inputs {
  gap: 18px;
  width: 75%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.meus-colaboradores-container12 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}

.meus-colaboradores-container12:hover {
  border-color: #4a4a4a;
}

.meus-colaboradores-icon2 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.meus-colaboradores-textinput {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}

.meus-colaboradores-container13 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0px;
  border-color: #3c3c3c;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 10px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}

.meus-colaboradores-container13:hover {
  border-color: #4a4a4a;
}

.meus-colaboradores-icon4 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.meus-colaboradores-textinput1 {
  color: #ffffff;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-top: 18px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 18px;
  background-color: transparent;
}

.meus-colaboradores-container14 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.meus-colaboradores-b-entrar {
  gap: 8px;
  width: 75%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  padding-top: 12px;
  padding-left: 14px;
  border-radius: 10px;
  padding-right: 14px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #E86811;
  cursor: pointer;
}

.meus-colaboradores-b-entrar:hover {
  background-color: #b7520e;
}

.meus-colaboradores-icon6 {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}

.meus-colaboradores-text20 {
  color: white;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
}

.meus-colaboradores-text21 {
  color: rgb(185, 185, 185);
  cursor: pointer;
  font-size: 12px;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}

.meus-colaboradores-text21:hover {
  color: #ffffff;
}

@media(max-width: 991px) {
  .meus-colaboradores-conteudo {
    padding: 20px;
  }
}

@media(max-width: 767px) {
  .meus-colaboradores-container02 {
    gap: 12px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .meus-colaboradores-container04 {
    align-self: center;
    justify-content: flex-start;
  }

  .meus-colaboradores-icon {
    width: 22px;
    height: 22px;
  }

  .meus-colaboradores-text {
    font-size: 24px;
  }

  .meus-colaboradores-text01 {
    font-size: 13px;
    align-self: center;
    text-align: center;
  }

  .meus-colaboradores-creditos {
    align-self: center;
  }

  .meus-colaboradores-text06 {
    font-size: 20px;
  }

  .meus-colaboradores-text07 {
    font-size: 20px;
  }

  .meus-colaboradores-b-continuar-aula {
    align-self: center;
  }

  .meus-colaboradores-text08 {
    font-size: 16px;
  }

  .meus-colaboradores-text09 {
    width: 40%;
    font-size: 15px;
  }

  .meus-colaboradores-text10 {
    width: 60%;
    font-size: 15px;
  }

  .meus-colaboradores-text11 {
    font-size: 15px;
  }

  .meus-colaboradores-text12 {
    width: 40%;
    font-size: 15px;
  }

  .meus-colaboradores-text13 {
    width: 60%;
    font-size: 15px;
  }

  .meus-colaboradores-text14 {
    color: white;
    font-size: 15px;
  }

  .meus-colaboradores-text15 {
    width: 60%;
    font-size: 15px;
  }
}

@media(max-width: 479px) {
  .meus-colaboradores-icon {
    display: none;
  }

  .meus-colaboradores-text {
    text-align: center;
  }

  .meus-colaboradores-b-continuar-aula {
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .meus-colaboradores-infos {
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 12px;
  }

  .meus-colaboradores-text12 {
    word-break: break-all;
  }

  .meus-colaboradores-text13 {
    word-break: break-all;
  }

  .meus-colaboradores-infos1 {
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 12px;
  }

  .meus-colaboradores-text15 {
    word-break: break-all;
  }
}

@media screen and (max-width:479px) {
  .meus-colaboradores-conteudo1 {
    width: 90%;
  }

  .meus-colaboradores-text19 {
    font-size: 20px;
  }

  .meus-colaboradores-container12,
  .meus-colaboradores-container13,
  .meus-colaboradores-inputs {
    width: 100%;
  }

  .meus-colaboradores-textinput,
  .meus-colaboradores-textinput1 {
    font-size: 14px;
  }

  .meus-colaboradores-text20 {
    font-size: 16px;
  }
}