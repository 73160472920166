.container {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.89);
    align-items: center;
    z-index:999999999999;
    flex-direction: column;
    justify-content: center;
  }
  .container1 {
    width: 90%;
    display: flex;
    max-width: 600px;
    background: #3b3b3b;
    border-radius: 10px;
    flex-direction: column;
  }
  .head {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: rgb(243, 243, 243);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .fechar-popup {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
  }
  .icon {
    fill: #3b3b3b;
    width: 18px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;
  }
  .icon:hover {
    filter: brightness(1.3);
  }
  .opcoes {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 20px;
    overflow: auto;
    flex-wrap: wrap;
    align-self: center;
    border-top: 1px solid #575757;
    max-height: 50vh;
    align-items: center;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    border-bottom: 1px solid #575757;
    flex-direction: row;
    justify-content: center;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .tabela {
    width: 100%;
    display: flex;
    position: relative;
    border-color: #3c3c3c;
    border-width: 1px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #3c3c3c;
  }
  .not-empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container3 {
    width: 100%;
    display: flex;
    align-self: flex-start;
    padding-top: 20px;
    border-color: #3c3c3c;
    border-width: 2px;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: space-between;
    background-color: #6d6d6d;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .text01 {
    color: white;
    width: 30%;
    font-style: normal;
    font-weight: 500;
  }
  .text02 {
    color: white;
    width: 40%;
    font-style: normal;
    font-weight: 500;
  }
  .text03 {
    color: white;
    width: 20%;
    font-style: normal;
    font-weight: 500;
  }
  .infos {
    display: flex;
    align-items: flex-start;
    border-color: #717171;
    border-width: 1px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #575757;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .info {
    gap: 28px;
    width: 100%;
    display: flex;
    position: relative;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: row;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
  .text04 {
    color: white;
    width: 30%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text05 {
    color: white;
    width: 40%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text06 {
    color: white;
    width: 20%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .paginao {
    gap: 20px;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: center;
    background-color: #6d6d6d;
  }
  .icon2 {
    fill: #c2c2c2;
    width: auto;
    height: 20px;
    cursor:pointer;
  }
  .text07 {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
  .icon4 {
    fill: #c2c2c2;
    width: auto;
    height: 20px;
    cursor:pointer;
  }
  .empty {
    gap: 24px;
    display: none;
    padding: 60px;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    background-color: #3c3c3c;
  }
  .icon6 {
    fill: rgba(255, 255, 255, 0.68);
    width: 50px;
    height: 50px;
  }
  .text08 {
    color: rgba(255, 255, 255, 0.68);
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  .head1 {
    width: 100%;
    display: flex;
    padding: 20px 30px;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    padding-right: 20px 30px;
    flex-direction: row;
    justify-content: space-between;
  }
  .text09 {
    color: white;
    width: 100%;
    overflow: hidden;
    font-size: 15px;
    align-self: center;
    text-align: right;
    white-space: nowrap;
    margin-bottom: 10px;
    text-overflow: ellipsis;
  }
  .text10 {
    font-weight: 700;
  }
  .btn-nao-mostrar-mais {
    gap: 8px;
    display: flex;
    align-self: center;
    transition: 0.3s;
    align-items: center;
    padding-top: 12px;
    padding-left: 14px;
    border-radius: 10px;
    cursor:pointer;
    padding-right: 14px;
    flex-direction: row;
    padding-bottom: 12px;
    justify-content: center;
    background-color: rgb(71 71 71);
  }
  .btn-nao-mostrar-mais:hover {
    filter:brightness(1.3)
  }
  .text12 {
    color: white;
    font-size: 18px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  .btn-nao-mostrar-mais1 {
    gap: 8px;
    display: flex;
    align-self: center;
    transition: 0.3s;
    align-items: center;
    padding-top: 12px;
    padding-left: 14px;
    border-radius: 10px;
    padding-right: 14px;
    flex-direction: row;
    padding-bottom: 12px;
    justify-content: center;
    cursor:pointer;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .btn-nao-mostrar-mais1:hover {
    filter:brightness(1.3)
  }
  .text13 {
    color: white;
    font-size: 18px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  @media(max-width: 767px) {
    .text01 {
      width: 40%;
      font-size: 15px;
    }
    .text02 {
      width: 40%;
      font-size: 15px;
    }
    .text03 {
      width: 40%;
      font-size: 15px;
    }
    .text04 {
      width: 40%;
      font-size: 15px;
    }
    .text05 {
      width: 40%;
      font-size: 15px;
    }
    .text06 {
      width: 40%;
      font-size: 15px;
    }
    .text08 {
      width: 60%;
      font-size: 15px;
    }
    .text09 {
      width: 40%;
      font-size: 15px;
    }
    .btn-nao-mostrar-mais {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .text12 {
      font-size: 16px;
    }
    .btn-nao-mostrar-mais1 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .text13 {
      font-size: 16px;
    }
  }
  @media(max-width: 479px) {
    .opcoes {
      flex-direction: column;
    }
    .infos {
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 12px;
    }
    .text04 {
      word-break: break-all;
    }
    .text05 {
      word-break: break-all;
    }
    .text06 {
      word-break: break-all;
    }
    .text09 {
      word-break: break-all;
    }
  }
  