.rich-text-editor {
    background-color: #6a6a6a;
    border-radius: 10px;
    width:100%;
}

.ql-toolbar.ql-snow {
    border:none;
    border-bottom: 1px solid #565656;
    box-sizing: border-box;
    font-family: inherit!important;
    padding: 8px;
}
.ql-container.ql-snow {
    border:none;
}
  
  .ql-container {
    background-color: #6a6a6a;
    color: #ffffff;
    border-radius:10px;
  }

  .ql-editor {
    color:#c6c6c6;
  }
