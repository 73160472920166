.admin-cursos-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding:30px;
    background-color: #212121;
  }
  .admin-cursos-cursos {
    gap: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .admin-cursos-container01 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-text {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .admin-cursos-btn-add-curso {
    gap: 8px;
    cursor: pointer;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: 5px;
    padding-right: 20px;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .admin-cursos-icon {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-add-modulo {
    color: var(--dl-color-default-white);
    cursor: pointer;
    height: auto;
    display: flex;
    font-style: normal;
    align-items: center;
    font-weight: 500;
    border-radius: 10px;
    justify-content: center;
  }
  .admin-cursos-lista-cursos {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }
  .admin-cursos-card {
    width: 19%;
    height:auto;
    display: flex;
    position: relative;
    flex-direction: column;
    text-decoration: none;
  }
  .admin-cursos-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    object-position: center;
  }
  .admin-cursos-info-curso {
    gap: var(--dl-space-space-halfunit);
    left: 0px;
    width: 100%;
    bottom: 0px;
    display: flex;
    padding: 16px;
    position: absolute;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.84);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .admin-cursos-text1 {
    color: #ffffff;
    font-weight:500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pointer {
    cursor:pointer!important;
  }
  .admin-cursos-text2 {
    color: rgba(185,185,185,1);
  }
  .admin-cursos-paginao {
    gap: 8px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .admin-cursos-icon02 {
    fill: #7e7e7e;
    width: auto;
    height: 20px;
  }
  .admin-cursos-text3 {
    color: #7e7e7e;
  }
  .admin-cursos-icon04 {
    fill: #7e7e7e;
    width: auto;
    height: 20px;
  }
  .admin-cursos-curso-edit {
    gap: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .admin-cursos-container02 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-container03 {
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-text4 {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .admin-cursos-container04 {
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-btn-edit-curso {
    gap: 8px;
    cursor: pointer;
    height: 45px;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-left: 20px;
    border-radius: 5px;
    padding-right: 20px;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .admin-cursos-btn-edit-curso:hover {
    filter: brightness(1.4);
  }
  .admin-cursos-icon06 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-add-modulo1 {
    color: var(--dl-color-default-white);
    cursor: pointer;
    height: auto;
    display: flex;
    font-style: normal;
    align-items: center;
    font-weight: 500;
    border-radius: 10px;
    justify-content: center;
  }
  .admin-cursos-container05 {
    width: 45px;
    cursor: pointer;
    height: 45px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
  }
  .admin-cursos-icon08 {
    fill: white;
    width: 24px;
    height: 24px;
  }
  .admin-cursos-btn-add-modulo {
    gap: 8px;
    cursor: pointer;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: 5px;
    padding-right: 20px;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .admin-cursos-icon10 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-add-modulo2 {
    color: var(--dl-color-default-white);
    cursor: pointer;
    height: auto;
    display: flex;
    font-style: normal;
    align-items: center;
    font-weight: 500;
    border-radius: 10px;
    justify-content: center;
  }
  .admin-cursos-modulos {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .admin-cursos-modulos > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

  .admin-cursos-modulo {
    gap: 10px;
    width: 100%;
    display: flex;
    padding: 15px;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #606060;
  }
  .admin-cursos-container06 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-container07 {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-icon12 {
    fill: white;
    width: auto;
    height: 28px;
  }
  .admin-cursos-text5 {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .admin-cursos-container08 {
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .admin-cursos-btn-add-aula {
    gap: 8px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    padding-right: 15px;
    flex-direction: row;
    justify-content: center;
    background-color: #4a4a4a;
  }
  .admin-cursos-icon14 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn {
    color: var(--dl-color-default-white);
    cursor: pointer;
    height: auto;
    display: flex;
    font-style: normal;
    align-items: center;
    font-weight: 500;
    border-radius: 10px;
    justify-content: center;
  }
  .admin-cursos-btn-editar {
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    background-color: #4a4a4a;
  }
  .admin-cursos-icon16 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-ver-aulas {
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    background-color: #4a4a4a;
  }
  .admin-cursos-icon18 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-excluir {
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
  }
  .admin-cursos-icon20 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-aulas {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-end;
    align-items: flex-start;
    padding-left: 32px;
    flex-direction: column;
  }

  #box-aulas {
    width:100%;
    padding-left:32px;
  }

  .admin-cursos-aula {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-container09 {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .admin-cursos-icon22 {
    fill: white;
    width: auto;
    height: 28px;
  }
  .admin-cursos-text6 {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .admin-cursos-container10 {
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .admin-cursos-btn-editar1 {
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    background-color: #4a4a4a;
  }
  .admin-cursos-icon24 {
    fill: white;
    width: auto;
    height: 20px;
  }
  .admin-cursos-btn-excluir1 {
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #da0000;
    align-items: center;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
  }
  .admin-cursos-icon26 {
    fill: white;
    width: auto;
    height: 20px;
  }
  