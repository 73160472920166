.input-dark {
    color:#c6c6c6;
    width:100%;
    height: 40px;
    outline:none;
    padding: 0 20px;
    font-size: 14px;
    border-radius:10px;
    background:#6a6a6a;
  }

  .dropdown {
    color:#c6c6c6;
    width:100%;
    height:40px;
    outline:none;
    font-size:14px;
    background:#6a6a6a;
    padding-left:20px;
    padding-right:20px;
    border-radius:10px;
  }
  .tabela-aulas {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 30px;
    position: relative;
    flex-direction: column;
    padding-bottom: 30px;
    justify-content: flex-start;
    background-color: #212121;
  }
  .container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .btn-add {
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    height: 45px;
    display: flex;
    position: relative;
    align-self: flex-start;
    align-items: center;
    padding-top: 25px;
    padding-left: 20px;
    border-radius: 10px;
    padding-right: 20px;
    flex-direction: row;
    padding-bottom: 25px;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon {
    fill: white;
    width: auto;
    height: 20px;
  }
  .text01 {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .container01 {
    gap: 15px;
    display: flex;
    row-gap: 30px;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
  }
  .form {
    gap: 15px;
    flex: 1;
    display: flex;
    padding: 30px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #444444;
  }
  .text02 {
    color: white;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .container02 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text03 {
    color: white;
  }
  .container03 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text04 {
    color: white;
  }
  .form1 {
    gap: 15px;
    flex: 4;
    display: flex;
    padding: 30px;
    row-gap: 15px;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #444444;
  }
  .text05 {
    color: white;
    width: 100%;
    font-size: 22px;
    align-self: flex-start;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .container04 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text06 {
    color: white;
  }
  .container05 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text07 {
    color: white;
  }
  .container06 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text08 {
    color: white;
  }
  .container07 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text09 {
    color: white;
  }
  .container08 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text10 {
    color: white;
  }
  .container09 {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text11 {
    color: white;
  }
  @media(max-width: 767px) {
    .text {
      width: 40%;
      font-size: 15px;
    }
    .text01 {
      width: 40%;
      font-size: 15px;
    }
    .text02 {
      width: 40%;
      font-size: 15px;
    }
    .text05 {
      width: 40%;
      font-size: 15px;
    }
  }
  

  .container06 {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .image {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .fechar-popup1 {
    top: 40%;
    left: 40px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    position: absolute;
    transform: translateY(-40%);
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-laranjaapp);
  }
  .icon2 {
    fill: #fff;
    width: auto;
    height: 12px;
  }

  .resultados {
    width:100%;
    display:flex;
    gap:15px;
  }

  .resultados .resultado {
    width:48%;
    display:flex;
    flex-direction:column;
    gap:4px;
    cursor:pointer;
  }

  .resultados .resultado img {
    width:100%;
    height:70px;
    object-fit:contain;
    object-position:center;
    border-radius:5px;
    background:black;
  }

  .resultados .resultado p {
    color:#9e9e9e;
    font-size:12px;
  }